<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>登錄發票列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
          登錄發票列表
        </h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="exportInvoiceLog()"
            >匯出</b-button
          >
          <b-form-select
            v-model="query.type"
            :options="types"
            @change="handlePageReset"
          ></b-form-select>
          <b-form-select
            v-model="query.status"
            :options="statuses"
            @change="handlePageReset"
            class="ml-2"
          ></b-form-select>
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              placeholder="搜尋發票號碼、會員名稱、手機、外部編碼"
              v-model="query.keyword"
              v-on:keyup.enter="handlePageReset"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="invoiceLogs"
            :fields="fields"
            :busy="showLoading"
            @sort-changed="handleSort"
          >
            <template #cell(image)="data">
              <b-img
                v-if="data.item.image"
                :src="data.item.image.url"
                fluid
                thumbnail
                style="max-width: 100px; max-height: 100px; cursor: pointer"
                @click="showImage(data.item.image.url)"
              ></b-img>
            </template>
            <template #cell(type)="data">
                <div v-if="(data.item.type == consts.INVOICE_LOG_TYPES.TRADITIONAL)">傳統發票</div>
                <div v-else-if="(data.item.type == consts.INVOICE_LOG_TYPES.ELECTRONIC) && data.item.random">電子發票</div>
                <div v-else>載具發票</div>
            </template>
            <template #cell(action)="data">
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_VERIFY)"
                variant="inverse-info"
                :to="{
                  name: 'InvoiceLogVerify',
                  params: { invoiceLogId: data.item.id },
                }"
              >
                <span class="mdi mdi-check-circle-outline mr-1"></span>審核
              </b-button>
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_MODIFY)"
                variant="inverse-primary"
                :to="{
                  name: 'InvoiceLogView',
                  params: { invoiceLogId: data.item.id },
                }"
              >
                <span class="mdi mdi-eye mr-1"></span>查看
              </b-button>
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_MODIFY)"
                variant="inverse-warning"
                :to="{
                  name: 'InvoiceLogEdit',
                  params: { invoiceLogId: data.item.id },
                }"
              >
                <span class="mdi mdi-lead-pencil mr-1"></span>編輯
              </b-button>
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_DELETE)"
                variant="inverse-danger"
                @click="handleDelete(data.item.id)"
              >
                <span class="mdi mdi-delete mr-1"></span>刪除
              </b-button>
            </template>
          </b-table>
        </div>
      </div>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="totalRows"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import invoiceLogApi from "@/apis/invoice-log";
import moment from "moment";
import * as consts from "@/consts";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  data() {
    return {
      types: [
        { value: null, text: "全部類型" },
        { value: consts.INVOICE_LOG_TYPES.ELECTRONIC, text: "電子發票" },
        { value: consts.INVOICE_LOG_TYPES.ELECTRONIC_CARRIER, text: "載具發票" },
        { value: consts.INVOICE_LOG_TYPES.TRADITIONAL, text: "傳統發票" },
      ],
      statuses: [
        { value: null, text: "全部狀態" },
        { value: consts.INVOICE_LOG_STATUS.INIT, text: "等待處理" },
        { value: consts.INVOICE_LOG_STATUS.E_VERIFY, text: "電子發票 - 驗證中" },
        { value: consts.INVOICE_LOG_STATUS.E_VALID, text: "電子發票 - 已處理" },
        { value: consts.INVOICE_LOG_STATUS.E_INVALID, text: "電子發票 - 不存在" },
        { value: consts.INVOICE_LOG_STATUS.E_CANCEL, text: "電子發票 - 已作廢" },
        { value: consts.INVOICE_LOG_STATUS.T_VERIFY, text: "傳統發票 - 發票審核中 " },
        { value: consts.INVOICE_LOG_STATUS.T_VALID, text: "傳統發票 - 發票已審核" },
        { value: consts.INVOICE_LOG_STATUS.T_INVALID, text: "傳統發票 - 發票不合格" },
        { value: consts.INVOICE_LOG_STATUS.CANCEL, text: "發票已取消" },
      ],
      showLoading: false,
      fields: [
        { key: "image", label: "照片" },
        { key: "no", label: "發票字軌" },
        {
          key: "issued_at",
          label: "開立時間",
          formatter: (value) => {
            return value ? moment(value).format("YYYY-MM-DD") : "";
          },
        },
        { key: "random", label: "隨機碼" },
        {
          key: "customer",
          label: "會員",
          sortable: true,
          formatter: (value) => {
            return value ? value.name : "";
          },
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
          formatter: (value) => {
            return this.statuses.find((item) => item.value === value).text;
          },
        },
        {
          key: "type",
          label: "類型",
          sortable: true,
        },
        {
          key: "created_at",
          label: "登錄日期",
          sortable: true,
          formatter: (value) => {
            return value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "";
          },
        },
        { key: "action", label: "管理" },
      ],
      invoiceLogs: [],
      totalRows: 0,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        type: null,
        status: null,
        keyword: "",
        sort_by: "id",
        sort_desc: "desc",
      },
      consts,
    };
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  mounted() {
    this.init();
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = {
        type: null,
        status: null,
      };
      this.query = { ...defaultQuery, ...to.query };
      this.fetchInvoiceLogs();
    },
  },
  methods: {
    async init() {
      await this.fetchInvoiceLogs();
      this.initialized = true;
    },
    async fetchInvoiceLogs() {
      this.showLoading = true;
      await this.doFetchInvoiceLogs();
      this.showLoading = false;
    },
    async doFetchInvoiceLogs() {
      try {
        const { data } = await invoiceLogApi.list({
          ...this.query,
        });
        this.invoiceLogs = data.data;
        this.totalRows = data.meta.total;
      } catch (e) {
        console.log(e);
      }
    },
    showImage(url) {
      window.open(url, "_blank");
    },
    async handleDelete(invoiceLogId) {
      this.showLoading = true;
      await this.doHandleDelete(invoiceLogId);
      this.showLoading = false;
    },
    async doHandleDelete(invoiceLogId) {
      try {
        await invoiceLogApi.deleteInvoiceLog(invoiceLogId);
        this.$swal.fire({ icon: "success", title: "刪除成功" });
        this.fetchInvoiceLogs();
      } catch (e) {
        console.log(e);
      }
    },
    async exportInvoiceLog() {
      invoiceLogApi.exportInvoiceLog({ ...this.query }).then(() => {
        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      });
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.sort_desc = ctx.sortDesc ? "desc" : "asc";
      if (this.query.sort_by === "customer") {
        this.query.sort_by = "customer_id";
      }
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
